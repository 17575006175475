module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en"],"defaultLanguage":"en","i18nextOptions":{"interpolation":{"escapeValue":false}}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"preloadTags":true},
    },{
      plugin: require('../../node_modules/gatsby-plugin-mixpanel/gatsby-browser.js'),
      options: {"plugins":[],"apiToken":"e0100b53bea53562ccc73d0c2a29a191","enableOnDevMode":true},
    },{
      plugin: require('../../gatsby-theme-ecom/gatsby-browser.ts'),
      options: {"plugins":[],"siteMetadata":{"title":"TopResume","siteUrl":"https://stg.topresume.com","brandName":"TopResume","trailingSlash":false,"trustpilot":{"businessUnitId":"531e18250000640005785d59","reviewTags":["AcademicCV","CareerEvolution","Cover-Letter","ExecutivePriority","FederalResume","interview-prep","LinkedIn","Military","ProfessionalGrowth","result","ResumeRefresh","ResumeReview","shown-on-website","ThankYouLetter"]},"trustpilotTopInterview":{"businessUnitId":"59fa2e5f0000ff0005af48c3","reviewTags":["interview-prep","shown-on-website"]},"siteID":1,"locale":"en-US","i18nextLocale":"en","defaultResumeProduct":"resume","googleTagManagerCode":"GTM-KGGG6Z","intercomWidgetColor":"#038dff","intercomAppId":"ysdthg67","domainName":"topresume.com"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
